import { Button, Image, Modal } from "react-bootstrap";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WorkplaceIcon,
    WorkplaceShareButton
} from "react-share";
import { LOCAL_API } from "../utilities/constants";
import { toast } from "react-toastify";
import http from 'utilities/http';
import { getCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";
import isEmpty from "lodash/isEmpty";

export default function Index({ onShare, setOnShare, url, id }) {
    const shareCount = async () => {
        if (!id) return;
        if (isEmpty(getCookie(TOKEN_CONGDONG))) return;

        const res = await http(`community/article/shares/toggle`, {}, {
            body: { id: id },
            method: 'POST',
            token: "Bearer " + getCookie(TOKEN_CONGDONG),
        }, 'v2');
    };
    return (
        <Modal show={onShare} onHide={() => { setOnShare(!onShare); }} role="document">
            <Modal.Header closeButton>
                <h5 className="modal-title">Chia sẻ</h5>
            </Modal.Header>
            <Modal.Body>
                <ul style={{ display: "flex", justifyContent: "center" }}>
                    <li style={{ padding: "0px 10px" }} onClick={() => {
                        navigator.clipboard.writeText(LOCAL_API + url);
                        toast.info("Sao chép tới clipboard thành công!");
                        setOnShare(!onShare);
                    }}>
                        <Image style={{ width: "25px", height: "25px", margin: "3px", cursor: 'pointer' }} src="/images/icon-link.png" alt=""></Image>
                    </li>
                    <li style={{ padding: "0px 10px" }}
                        onClick={() => {
                            shareCount();
                            setOnShare(!onShare);
                        }}>
                        <FacebookShareButton url={LOCAL_API + url} className="share">
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                    </li>
                    <li style={{ padding: "0px 10px" }}
                        onClick={() => {
                            shareCount();
                            setOnShare(!onShare);
                        }}>
                        <EmailShareButton url={LOCAL_API + url} className="share">
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </li><li style={{ padding: "0px 10px" }}
                        onClick={() => {
                            shareCount();
                            setOnShare(!onShare);
                        }}>
                        <TwitterShareButton url={LOCAL_API + url} className="share">
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                    </li>
                    <li style={{ padding: "0px 10px" }}
                        onClick={() => {
                            shareCount();
                            setOnShare(!onShare);
                        }}>
                        <LinkedinShareButton url={LOCAL_API + url} className="share">
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                    </li>
                    <li style={{ padding: "0px 10px" }}
                        onClick={() => {
                            shareCount();
                            setOnShare(!onShare);
                        }}>
                        <TelegramShareButton url={LOCAL_API + url} className="share">
                            <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                    </li>
                    <li style={{ padding: "0px 10px" }}
                        onClick={() => {
                            shareCount();
                            setOnShare(!onShare);
                        }}>
                        <WorkplaceShareButton url={LOCAL_API + url} className="share">
                            <WorkplaceIcon size={32} round={true} />
                        </WorkplaceShareButton>
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer className="chooseTheme-btn" style={{ display: "flex", justifyContent: "center" }}>
                <Button style={{ width: "50%" }} variant="primary" onClick={() => { setOnShare(!onShare); }} className="btn">
                    Xong
                </Button>
            </Modal.Footer>
        </Modal>
    );
}