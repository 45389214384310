/* eslint-disable @next/next/no-img-element */
import { getCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
// import { Image } from "react-bootstrap";
import Image from 'next/image';
import { toast } from "react-toastify";
import http from "utilities/http";
import { numberWithCommas, changeMonthFirst } from "utilities/helper";
import Share from "components/share";
import dayjs from "dayjs";
import {useCart} from "../../framework/store";

const LABEL = {
    0: 'Nháp',
    1: '',
    2: 'Chờ duyệt',
    100: 'Mới'
};

export default function ImageComponent({ item, index, slug = "album", isWatchMost = false }) {

    const [currentLike, setCurrentLike] = useState(null);
    const [currentPin, setCurrentPin] = useState(null);
    const [numberLike, setNumberLike] = useState(null);
    const [numberPin, setNumberPin] = useState(null);

    const [onShare, setOnShare] = useState(false);

    useEffect(() => {
        setCurrentLike(item.current_user_liked);
        setCurrentPin(item.current_user_pinned);
        setNumberLike(item?.interaction_count?.like_count_number);
        setNumberPin(item?.interaction_count?.pin_count_number);
    }, [item]);

    const actionLike = async (postType, id, act) => {
        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }
        if (act === 'likes') {
            if (currentLike) setNumberLike(numberLike - 1);
            if (!currentLike) setNumberLike(numberLike + 1);
            setCurrentLike(!currentLike);
        };
        if (act === 'pins') {
            if (currentPin) setNumberPin(numberPin - 1);
            if (!currentPin) setNumberPin(numberPin + 1);

            setCurrentPin(!currentPin);
        };

        const res = await http(`community/${postType}/${act}/toggle`, {}, {
            body: { id: id },
            method: 'POST',
            token: "Bearer " + getCookie(TOKEN_CONGDONG)
        }, 'v2');
    };

    let link = ((item.status_code == 0 || item.status_code == 2) ? '/preview' : '') + `/${slug}/` + item.id + '/' + item.slug;

    if (item.storage_emagazine_url) {
        link = ((item.status_code == 0 || item.status_code == 2) ? '/preview' : '') + `/happynest-story/` + item.id + '/' + item.slug;
    }
    const [stateStore, actions] = useCart();
    if(!isEmpty(stateStore?.informationSite?.slug)){
        link +=`?site_url=${stateStore?.informationSite?.slug}`;
    }
    const actionTool = <ul className="listPhoto-tools">
        <li>
            <span>
                <img alt="" width={23} height={23} src={currentLike ? "/images/icon-heart.svg" : "/images/icon-heart01.svg"}
                    onClick={() => actionLike(item?.post_type, item?.id, 'likes')} />
            </span>
            {numberLike}
        </li>
        <li>
            <span>
                <a href={link + '#comments'}>
                    <img src="/images/icon-comment.svg" alt="" />
                </a>
            </span>
            {item?.interaction_count?.comment_count_number}
        </li>
        <li>
            <span>
                <img src="/images/icon-share01.svg" alt="" onClick={() => { setOnShare(!onShare); }} />
            </span>
            {item?.interaction_count?.post_count_number}
        </li>
        <li>
            <span>
                <img width={20} height={25} alt=""
                    src={currentPin ? "/images/icon-bookmark03.svg" : "/images/icon-bookmark02.svg"}
                    onClick={() => actionLike(item?.post_type, item?.id, 'pins')} />
            </span>
            {numberPin}
        </li>
    </ul>;

    let thumbnail = item.storage_thumbnail_url_wide || item.storage_thumbnail_url || item.storage_thumbnail_url_large;
    if (item.storage_thumbnail_url_large === 'https://happynest.vn/home2017-assets/images/avatar-happynest@2x.jpg' && !isEmpty(item.images?.data)) {
        thumbnail = item.images?.data[0]?.storage_thumbnail_url || item.images?.data[0]?.storage_url;
    }

    if (item.status_code == 1 && dayjs().diff(dayjs(changeMonthFirst(item.published_at_detail_formatted)), 'day') < 2) {
        item.status_code = 100;
    }

    return (
        <li key={index}>
            <div className="listPhoto-thumb">
                {isWatchMost && index != null && <span className="stt-number">{index + 1}</span>}
                <a href={link} className="wrap-next-image">
                    {/* <Image layout={"fill"} src={thumbnail} alt="" /> */}
                    <img src={thumbnail} alt="" />
                </a>
                {isWatchMost && index == null && <div className="photoStore-lable"><span>Mới</span></div>}
                {!isWatchMost && LABEL[item.status_code] && <div className="photoStore-lable"><span>{LABEL[item.status_code]}</span></div>}
                {(item.post_type == 'video' || item.first_term?.id == 303 || slug == 'happynesttv') && <a href={link}><div className="play-button">
                    <img style={{ width: 70, height: 70 }} className="svg" src="/images/Play.png" alt="" />
                </div></a>}
                <div className="read-views">
                    <img className="svg" src="/images/icon-tick.svg" alt="" style={{ marginBottom: 2 }} />
                    <span className="number">{numberWithCommas(item.interaction_count?.view_count_number)}</span>
                </div>
                {!isEmpty(item?.images?.data) && <div className="listPhoto-number">
                    +{item?.images?.data.length || 0}
                </div>}
                <button className="btn-save-bookmart sp-only" onClick={() => actionLike(item?.post_type, item?.id, 'pins')} style={{
                    right: 8,
                    bottom: 8,
                }}>
                    <Image width={18} height={21} src={currentPin ? "/images/icon-bookmark03.svg" : "/images/icon-bookmark.svg"} align="" alt="" />
                </button>
            </div>

            {actionTool}

            <h3 className="listPhoto-title">
                <a href={link}>
                    {item?.title}
                </a>
            </h3>
            <p className="listPhoto-label">{item?.user?.display_name || ''}</p>
            <Share onShare={onShare} setOnShare={setOnShare} url={`/${slug}/` + item.id + '/' + item.slug} />
        </li>
    );
}